import TravelStorySlider from '@components/molecules/TravelStorySlider';
import styles from './css';

const TravelStoryLPSection = () => {
  return (
    <div className="travel-story-section">
      <TravelStorySlider key="latest-travel-story-slider" type="latest" />
      <TravelStorySlider key="popular-travel-story-slider" type="popular" />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TravelStoryLPSection;
