import { FC } from 'react';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import CheckCirckIcon from '@assets/top/check-circle.svg';
import { TypoSBold, TypoXS } from '@atoms/Typos';
import type {
  IntroductionSectionKeyOfKebab,
  IntroductionSectionKeyOfLowerCamel,
} from '@features/lp/constants';
import { useGetIntroductionImagePath } from '@features/lp/hooks/useGetIntroductionImagePath';
import { useIsJapanUser } from '@utils/hooks';
import styles from './css';

type Props = {
  detailIndex: number;
  sectionKeyNameOfKebab: IntroductionSectionKeyOfKebab;
  sectionKeyNameOfLowerCamel: IntroductionSectionKeyOfLowerCamel;
};

const SPECIFIC_JAPAN_REGION_TITLE_KEYS = ['save-and-use-2'];

const SPECIFIC_JAPAN_REGION_DESCRIPTION_KEYS = [
  'save-and-use-1',
  'save-and-use-2',
];

const IntroductionDetail: FC<Props> = ({
  detailIndex,
  sectionKeyNameOfKebab,
  sectionKeyNameOfLowerCamel,
}) => {
  const { t } = useTranslation('top');
  const isJapanUser = useIsJapanUser();
  const imagePath = useGetIntroductionImagePath(
    sectionKeyNameOfKebab,
    detailIndex
  );

  const getJapanRegionTitle = () => {
    const currentKey = `${sectionKeyNameOfKebab}-${detailIndex}`;

    if (SPECIFIC_JAPAN_REGION_TITLE_KEYS.includes(currentKey)) {
      if (isJapanUser) {
        return `introductionSection.${sectionKeyNameOfLowerCamel}.contents${detailIndex}.title-ja`;
      }
    }
    return `introductionSection.${sectionKeyNameOfLowerCamel}.contents${detailIndex}.title`;
  };

  const getJapanRegionDescription = () => {
    const currentKey = `${sectionKeyNameOfKebab}-${detailIndex}`;

    if (SPECIFIC_JAPAN_REGION_DESCRIPTION_KEYS.includes(currentKey)) {
      if (isJapanUser) {
        return `introductionSection.${sectionKeyNameOfLowerCamel}.contents${detailIndex}.description-ja`;
      }
    }
    return `introductionSection.${sectionKeyNameOfLowerCamel}.contents${detailIndex}.description`;
  };

  return (
    <div className="introduction-detail">
      <div>
        <div className="introduction-detail-title">
          <CheckCirckIcon />
          <div className="introduction-detail-title-text">
            <TypoSBold>{t(getJapanRegionTitle())}</TypoSBold>
          </div>
        </div>
        <div className="introduction-detail-description">
          <TypoXS>{t(getJapanRegionDescription())}</TypoXS>
        </div>
      </div>
      <div className="introduction-detail-body">
        <Image
          fill
          alt={t(
            `introductionSection.${sectionKeyNameOfLowerCamel}.contents${detailIndex}.title`
          )}
          src={imagePath}
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IntroductionDetail;
