import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  :global(.tips-of-using-hafh-slider .tips-of-using-hafh-card) {
    display: block;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    background-color: ${COLORS.white};
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
    }
    .thumbnail {
      height: 200px;
      position: relative;
      &::after {
        position: relative;
        content: '';
        display: block;
        height: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }
    }
    :global(.thumbnail-image) {
      object-fit: cover;
    }
    .tag {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
    }
    .title {
      position: absolute;
      bottom: 16px;
      z-index: 1;
      padding: 0 16px;
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
    }
    .profile {
      display: flex;
      align-items: flex-start;
      gap: 12px;
    }
    .profile-image {
      border-radius: 50%;
      overflow: hidden;
    }
    .profile-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    .description {
      :global(p) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 6;
      }
    }
  }
`;

export default styles;
