import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import PropertySlider from '@molecules/PropertySlider';
import { Property } from '@services/hafh/types/generated';

type Props = {
  getReservationCountRanking: () => void;
  properties: Property[];
};

const ReservationCountRankingProperties: FC<Props> = ({
  getReservationCountRanking,
  properties,
}) => {
  const { t } = useTranslation('browse');

  return (
    <PropertySlider
      getPropertiesByArea={getReservationCountRanking}
      isAreaTabs={true}
      name="reservation-count-ranking"
      properties={properties}
      sliderType="ranking"
      title={t('monthlyRanking')}
    />
  );
};

export default ReservationCountRankingProperties;
