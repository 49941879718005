import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .introduction-detail {
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex: 1;
      gap: 4px;
    }
  }
  .introduction-detail-title {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .introduction-detail-title-text {
    flex: 1;
  }
  .introduction-detail-description {
    margin-top: 8px;
  }
  .introduction-detail-body {
    position: relative;
    height: 100%;
    margin-top: 16px;
    border-radius: 8px;
    overflow: hidden;
    aspect-ratio: 295 / 183;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      height: 191px;
      min-height: max-content;
      aspect-ratio: 300 / 191;
    }
    @media screen and (min-width: ${BREAKPOINT.tablet}px) {
      height: 251px;
      aspect-ratio: 396 / 251;
    }
  }
`;

export default styles;
