import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as travelStoriesListsActions from '@redux/modules/travelStoriesLists';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE, TravelStoriesTabsType } from '@utils/types';

type Props = {
  page?: number;
  type: TravelStoriesTabsType;
};

export const useGetTravelStories = ({ page = 1, type }: Props) => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser, travelStoriesLists } = useAppSelector(
    (state) => ({
      authUser: state.authUser,
      loadingAuthUser: state.loadingAuthUser,
      travelStoriesLists: state.travelStoriesLists,
    })
  );
  const { getTravelStoriesList, resetTravelStoriesList } = useActions({
    ...travelStoriesListsActions,
  });

  useEffect(() => {
    // if locale is updated to a different language, reset the list
    if (travelStoriesLists[type].language?.abbreviation !== locale) {
      resetTravelStoriesList(type);
      getTravelStoriesList(type, page, locale, authUser);
      return;
    }

    if (
      // get page 1 getTravelStoriesList only if the current page is 0
      (page === 1 && travelStoriesLists[type].current_page > 0) ||
      loadingAuthUser
    ) {
      return;
    }

    getTravelStoriesList(type, page, locale, authUser);
  }, [type, page, loadingAuthUser, locale]);
};
