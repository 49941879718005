import { FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import Swiper from '@atoms/Swiper';
import type {
  TipsOfUsingHafhForJapan,
  TipsOfUsingHafhForTaiwan,
} from '@features/topics/types';
import { BREAKPOINT } from '@utils/constants';
import TipsOfUsingHafhCard from '../TipsOfUsingHafhCard';
import styles from './css';

const options = {
  breakpoints: {
    [BREAKPOINT.desktop]: {
      slidesPerView: 2,
      spaceBetween: 24,
    },
    [BREAKPOINT.desktopL]: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    [BREAKPOINT.mobileS]: {
      slidesPerView: 1.1,
      spaceBetween: 16,
    },
  },
  centeredSlides: false,
  loop: false,
  observer: true,
  slidesPerView: 1.1,
  spaceBetween: 16,
};

type Props = PropsJa | PropsTw;

type PropsJa = {
  contents: TipsOfUsingHafhForJapan;
  lang: 'ja';
};

type PropsTw = {
  contents: TipsOfUsingHafhForTaiwan;
  lang: 'zh-tw';
};

const TipsOfUsingHafhSection: FC<Props> = ({ contents, lang }) => {
  return (
    <div className="tips-of-using-hafh-slider">
      <Swiper
        hasSwiperButton={true}
        name="tips-of-using-hafh"
        options={options}
      >
        {contents.map((item) => (
          <SwiperSlide key={item.id}>
            {lang === 'ja' ? (
              <TipsOfUsingHafhCard
                item={item as TipsOfUsingHafhForJapan[number]}
                lang="ja"
              />
            ) : (
              <TipsOfUsingHafhCard
                item={item as TipsOfUsingHafhForTaiwan[number]}
                lang="zh-tw"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TipsOfUsingHafhSection;
