import css from 'styled-jsx/css';

const styles = css`
  .property-section {
    display: flex;
    flex-direction: column;
    gap: 48px;
    :global(.section-header) {
      padding-top: 0;
    }
    :global(.swiper) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

export default styles;
