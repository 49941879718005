import { FC } from 'react';
import { TypoXSBold } from '@atoms/Typos';
import styles from './css';

type Props = {
  tagName: string;
};

const TipsOfUsingHafhTag: FC<Props> = ({ tagName }) => {
  return (
    <div className="tips-of-using-hafh-tag">
      <TypoXSBold color="white" text={`HafH x ${tagName}`} />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default TipsOfUsingHafhTag;
