import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .tips-of-using-hafh-slider {
    :global(.swiper) {
      padding-bottom: 12px; /* for box-shadow */
      padding-left: 16px;
      padding-right: 16px;
      :global(.swiper-button-prev),
      :global(.swiper-button-next) {
        position: absolute;
        top: calc(50% - 24px);
        width: 40px;
        height: 40px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        transition: all 0.3s;
        opacity: 0.35;
        &:hover {
          opacity: 1;
        }
      }
      :global(.swiper-slide) {
        height: auto;
      }
      :global(.swiper-button-disabled) {
        display: none;
      }
      :global(.swiper-button-next) {
        right: 32px;
      }
      :global(.swiper-button-prev) {
        left: 32px;
        @media (max-width: ${BREAKPOINT.desktop}px) {
          display: none;
        }
      }
    }
  }
`;

export default styles;
