import ReservationCountRankingProperties from '@features/properties/components/ReservationCountRankingProperties';
import { useGetReservationCountRanking } from '@features/properties/hooks/useGetReservationCountRanking';
import { useAppSelector } from '@hooks/useAppSelector';
import * as reservationCountRankingActions from '@redux/modules/reservationCountRanking';
import { useActions } from '@utils/hooks';

const ReservationCountRankingPropertiesContainer = () => {
  const { reservationCountRanking } = useAppSelector((state) => ({
    reservationCountRanking: state.reservationCountRanking,
  }));
  const { getReservationCountRanking } = useActions({
    ...reservationCountRankingActions,
  });

  useGetReservationCountRanking();

  if (!reservationCountRanking?.length) {
    return null;
  }

  return (
    <ReservationCountRankingProperties
      getReservationCountRanking={getReservationCountRanking}
      properties={reservationCountRanking}
    />
  );
};

export default ReservationCountRankingPropertiesContainer;
