export const TIPS_OF_USING_HAFH_FOR_JAPAN = [
  {
    description:
      '以前は中学校の教員だったというLeoさん。夏休みなどの長期休暇を利用して旅行できると思っていましたが、実際には旅行を計画する暇がありませんでした。そのため、時間ができた時にはすでに予約でいっぱいだったり、繁忙期で価格が高騰していることもあって、思うように旅行に行くことができなかったんだそうです。今は教員を辞め、以前勤めていた学校の図書館などでフリーランスとして勤務。',
    id: '01',
    name: 'Leoさん',
    occupation: 'フリーランス',
    startDateOfUse: '2022年8月利用開始',
    tag: '旅する暮らし',
    title: '教員時代に積み立てたコインで楽しむ毎月の旅行',
    topicId: '22616',
  },
  {
    description:
      '旅は特別なものではなく、日常の延長線上にある。そんな新しい旅のスタイルを実現しているのが、旅行代理店に勤務しながら現在5歳の息子と頻繁に旅をするともちさんです。彼女はHafHをフル活用し、ほぼ毎週のように旅行を楽しんでいます。今回は、ともちさんがHafHをどのように使いこなし、どんな価値を見出しているのか、そのライフスタイルを紹介したいと思います。',
    id: '02',
    name: 'ともちさん',
    occupation: '旅行代理店パート',
    startDateOfUse: '2021年7月利用開始',
    tag: '親子旅',
    title: 'HafHで母子旅！コインを使って気軽に楽しむ週末旅行',
    topicId: '22703',
  },
  {
    description:
      'HafHについてコロナ禍前から存在は知っていたものの、当時は宿泊費が全体的に安かったため、必要性を感じていなかったというしゅんたろうさん。しかし、コロナ禍後に宿泊費が高騰してからHafHの価格設定に魅力を感じ、利用を検討。ネットで調べている最中に「招待コードを使って登録すると招待特典がもらえる」という情報を知ったことがきっかけとなり、利用を開始しました。',
    id: '03',
    name: 'しゅんたろうさん',
    occupation: '会社員',
    startDateOfUse: '2023年10月利用開始',
    tag: '出張',
    title: '曜日や価格に縛られない「自由な旅」をかなえるHafH',
    topicId: '22433',
  },
  {
    description:
      '2020年に当時勤めていた会社を辞めたタイミングで、世界一周旅行を計画したというYokoさん。しかしコロナ禍のため実現が難しく、代わりに「東京以外に自由に住んでみよう」と思い立ちました。いくつかの旅行系サブスクリプションサービスを調べる中でHafHの存在を知り、海外のホテルも取り扱っている点が決め手となり、利用を開始。',
    id: '04',
    name: 'Yokoさん',
    occupation: 'Webライター、編集者',
    startDateOfUse: '2020年5月利用開始',
    tag: '旅する暮らし',
    title: 'HafHが実現する、フリーランスの旅する暮らし',
    topicId: '22469',
  },
  {
    description:
      '栃木県日光市を拠点に、全国を飛び回りながらフリーランスの空間デザイナーとして働く高橋さん。店舗の内装や広報、SNSの運用など、多岐にわたる仕事を手がけながら、旅をライフスタイルに組み込んでいます。会社員時代から旅が好きで、土日は頻繁に遠出していました。そんな高橋さんがHafHを知ったのは、キャンペーンがきっかけでした。',
    id: '05',
    name: '髙橋広野さん',
    occupation: '空間デザイナー',
    startDateOfUse: '2021年8月利用開始',
    tag: 'ワーケーション',
    title: 'HafHで広がる旅と仕事の新しいカタチ',
    topicId: '22138',
  },
] as const;

export const TIPS_OF_USING_HAFH_FOR_TAIWAN = [
  {
    description:
      'U1 的大學期間選修了關於日本的課程，並且有機會參加了新潟的交換留學計劃。這段難能可貴的經驗，尤其是新潟的豐富觀光資源，如三大煙火、夜櫻，以及神似《神隱少女》中「盆舟」的佐渡島，這些美好的經驗成為了他留在日本的契機。',
    id: '01',
    name: '日本有個U1 YuiTube',
    occupation: 'YouTuber',
    tag: '探索日本',
    title: '透過旅行，認識活力滿滿的日本。',
    topicId: '21149',
  },
  {
    description:
      '身為廣告代理商創意總監兼普通煩惱自救會 Podcast 主持人的家瑜，與我們分享她當時那股「反正我是去定了」在腦海中隱隱作祟的念頭、即便知道得認命加碼預算卻發現事情（各家浮動價格）並不單純，最終透過 HafH 找到了成行的曙光，而且幸運地搭上了關東櫻花滿開的列車。',
    id: '02',
    name: '普通煩惱自救會 家瑜',
    occupation: '廣告代理商',
    tag: '說走就走',
    title: '「反正我是去定了」—享受任性的賞櫻之旅',
    topicId: '19377',
  },
  {
    description:
      '長期居住在日本並樂於和大家分享飯店相關資訊的東京飯店通，其實早在數年前就有申辦 HafH 的會籍！而隨著後續疫情趨緩、HafH 的服務更新進化，讓三不五時會遊走在眾多訂房平台的他，對於這次使用全新 HafH 服務入住新開幕的旅宿，在日後旅宿選擇安排上，有了嶄新的體會。',
    id: '03',
    name: 'Tokyo Hotel Maniac',
    occupation: '自由業',
    tag: '說走就走',
    title: '不帶特定目的一泊，享受嶄新視角的東京！',
    topicId: '19034',
  },
] as const;
