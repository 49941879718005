import { FC } from 'react';
import Image from 'next/image';
import { TypoMBold, TypoSBold, TypoXS, TypoXXS } from '@atoms/Typos';
import Link from '@components/Link';
import TipsOfUsingHafhTag from '@features/topics/components/TipsOfUsingHafhTag';
import type {
  TipsOfUsingHafhForJapan,
  TipsOfUsingHafhForTaiwan,
} from '@features/topics/types';
import styles from './css';

type Props = PropsJa | PropsTw;

type PropsJa = {
  item: TipsOfUsingHafhForJapan[number];
  lang: 'ja';
};

type PropsTw = {
  item: TipsOfUsingHafhForTaiwan[number];
  lang: 'zh-tw';
};

const TipsOfUsingHafhCard: FC<Props> = ({ item, lang }) => {
  return (
    <Link className="tips-of-using-hafh-card" href={`/topics/${item.topicId}`}>
      <div className="thumbnail">
        <Image
          fill
          alt="thumbnail"
          className="thumbnail-image"
          src={`/images/tips-of-using-hafh/${lang}/thumbnail-${item.id}.jpg?v=2`}
        />
        <div className="tag">
          <TipsOfUsingHafhTag tagName={item.tag} />
        </div>
        <div className="title">
          <TypoMBold color="white" tag="p" text={item.title} />
        </div>
      </div>
      <div className="body">
        <div className="profile">
          <div className="profile-image">
            <Image
              alt="profile icon"
              height={40}
              src={`/images/tips-of-using-hafh/${lang}/profile-icon-${item.id}.jpg?v=2`}
              width={40}
            />
          </div>
          <div className="profile-text">
            <TypoSBold tag="p" text={item.name} />
            <div>
              {lang === 'ja' && (
                <>
                  <TypoXXS text={item.startDateOfUse} />
                  <TypoXXS text="・" />
                </>
              )}
              <TypoXXS text={item.occupation} />
            </div>
          </div>
        </div>
        <div className="description">
          <TypoXS tag="p" text={item.description} />
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default TipsOfUsingHafhCard;
