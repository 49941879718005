import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .introduction-item {
    padding: 40px 24px;
    border: 1px solid ${COLORS.black200};
    border-radius: 16px;
    background-color: ${COLORS.white};
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      padding: 48px 40px;
    }
  }
  .introduction-item-head {
    text-align: center;
  }
  .introduction-item-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 32px;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
      margin-top: 40px;
    }
  }
`;

export default styles;
