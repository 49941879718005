import css from 'styled-jsx/css';

const styles = css`
  .tips-of-using-hafh-tag {
    padding: 2px 4px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export default styles;
