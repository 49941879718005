import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { TypoLBold, TypoS } from '@atoms/Typos';
import styles from './css';

type Props = {
  children: ReactNode;
  hasBorder?: boolean;
  hasPaddingHorizontal?: boolean;
  hasTriangle?: boolean;
  shouldDisplayHeaderSection?: boolean;
  subTitle?: string;
  title: string;
};

const LPSection: FC<Props> = ({
  children,
  hasBorder = true,
  hasPaddingHorizontal = true,
  hasTriangle = false,
  shouldDisplayHeaderSection = true,
  subTitle,
  title,
}) => {
  return (
    <section
      className={classNames(
        'lp-section',
        hasBorder && 'has-border',
        hasPaddingHorizontal && 'has-padding-horizontal'
      )}
    >
      {shouldDisplayHeaderSection && (
        <div className="lp-section-head">
          <TypoLBold>{title}</TypoLBold>
          {subTitle && (
            <>
              <div className="mt-12" />
              <TypoS>{subTitle}</TypoS>
            </>
          )}
          <div className="mt-32" />
          <span className="lp-section-head-border" />
        </div>
      )}
      {children}
      {hasTriangle && <div className="has-triangle" />}
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default LPSection;
