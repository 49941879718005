import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Button from '@atoms/Button';
import { TypoLBold, TypoMBold, TypoS } from '@atoms/Typos';
import routes from '@utils/routes';
import styles from './css';

const HafhGuide = () => {
  const { t } = useTranslation('guide');
  const { locale } = useRouter();

  return (
    <section className="hafh-guide">
      <div className="section-inner">
        <div className="title">
          <TypoLBold text={t('learnMore.title')} />
        </div>
        <div className="card-setcion">
          <div className="card-image">
            <Image
              fill
              alt={t('meta.title')}
              src={`/images/lp/top/guide-banner-${locale}.png`}
              style={{
                objectFit: 'cover',
              }}
            />
          </div>
          <div className="card-body">
            <div className="card-text-section">
              <TypoMBold tag="p" text={t('meta.title')} />
              <div className="divider" />
              <TypoS tag="p" text={t('meta.description')} />
            </div>
            <div className="card-cta">
              <Button
                label={t('learnMore.cta')}
                link={routes.guide}
                position="right"
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </section>
  );
};

export default HafhGuide;
