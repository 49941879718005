import TravelStoryLPSection from '@features/travel-stories/components/TravelStoryLPSection';
import { useGetTravelStories } from '@features/travel-stories/hooks/useGetTravelStories';

const TravelStoryLPSectionContainer = () => {
  useGetTravelStories({
    type: 'latest',
  });
  useGetTravelStories({
    type: 'popular',
  });

  return <TravelStoryLPSection />;
};

export default TravelStoryLPSectionContainer;
