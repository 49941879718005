import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAppSelector } from '@hooks/useAppSelector';
import * as propertyHistoryActions from '@redux/modules/propertyHistory';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';

export const useGetPropertyHistory = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { authUser, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    loadingAuthUser: state.loadingAuthUser,
  }));
  const { getPropertyList } = useActions({
    ...propertyHistoryActions,
  });

  useEffect(() => {
    if (loadingAuthUser) {
      return;
    }

    const propertyIds = JSON.parse(
      localStorage.getItem('property_history') || '[]'
    );

    if (propertyIds.length > 0) {
      getPropertyList(locale, { id: propertyIds }, authUser);
    }
  }, [loadingAuthUser]);
};
