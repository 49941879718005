export const INTRODUCTION_SECTION_KEYS = [
  // "kebabCase" is for image path, "lowerCamelCase" is for translation key
  {
    kebabCase: 'save-and-use',
    lowerCamelCase: 'saveAndUse',
  },
  {
    kebabCase: 'continue-to-save',
    lowerCamelCase: 'continueToSave',
  },
  {
    kebabCase: 'because-of-hafh',
    lowerCamelCase: 'becauseOfHafh',
  },
] as const;

export type IntroductionSectionKeyOfKebab =
  (typeof INTRODUCTION_SECTION_KEYS)[number]['kebabCase'];

export type IntroductionSectionKeyOfLowerCamel =
  (typeof INTRODUCTION_SECTION_KEYS)[number]['lowerCamelCase'];
