import css from 'styled-jsx/css';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .introduction-section {
    max-width: 912px;
    margin: 0 auto;
  }
  .introduction-section-catch {
    display: flex;
    justify-content: center;
    :global(.typo) {
      text-align: center;
      background: linear-gradient(90deg, #c44ca2 20.1%, #7149ce 77.53%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .introduction-section-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      gap: 32px;
    }
  }
  .mt-40 {
    margin-top: 40px;
  }
`;

export default styles;
