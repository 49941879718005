import TipsOfUsingHafhSection from '@features/topics/components/TipsOfUsingHafhSection';
import { TIPS_OF_USING_HAFH_FOR_JAPAN } from '@features/topics/constants';

const TipsOfUsingHafhForJapanContainer = () => {
  return (
    <TipsOfUsingHafhSection contents={TIPS_OF_USING_HAFH_FOR_JAPAN} lang="ja" />
  );
};

export default TipsOfUsingHafhForJapanContainer;
