import css from 'styled-jsx/css';
import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const styles = css`
  .lp-section {
    position: relative;
    max-width: 1232px;
    margin: 0 auto;
    padding: 16px 0 56px;
    &.has-border {
      border-bottom: 1px solid ${COLORS.black300};
    }
    &.has-padding-horizontal {
      padding-right: 16px;
      padding-left: 16px;
      .lp-section-head {
        padding: 32px 0 40px;
      }
    }
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      padding: 32px 24px 56px;
    }
  }
  .lp-section-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 16px 40px;
    text-align: center;
  }
  .lp-section-head-border {
    display: block;
    width: 24px;
    height: 4px;
    border-radius: 2px;
    background-color: ${COLORS.purple500};
  }
  .has-triangle {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 20px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
    background-color: ${COLORS.black100};
  }
  .mt-12 {
    margin-top: 12px;
  }
  .mt-32 {
    margin-top: 32px;
  }
`;

export default styles;
