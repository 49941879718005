import { useRouter } from 'next/router';
import type { IntroductionSectionKeyOfKebab } from '@features/lp/constants';
import { useIsJapanUser } from '@utils/hooks';

const IMAGE_PATHS = '/images/top/introduction';

export const useGetIntroductionImagePath = (
  sectionKeyNameOfKebab: IntroductionSectionKeyOfKebab,
  index: number
) => {
  const { locale } = useRouter();
  const isJapanUser = useIsJapanUser();

  const imageName = `${sectionKeyNameOfKebab}-${index}` as const;

  if (imageName === 'save-and-use-2') {
    if (isJapanUser) {
      return `${IMAGE_PATHS}/save-and-use-2-with-flight.png`;
    }
    return `${IMAGE_PATHS}/save-and-use-2-without-flight.png`;
  }

  if (imageName === 'continue-to-save-2') {
    return `${IMAGE_PATHS}/continue-to-save-2.png`;
  }

  return `${IMAGE_PATHS}/${imageName}-${locale}.png`;
};
