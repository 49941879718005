import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as reservationCountRankingActions from '@redux/modules/reservationCountRanking';
import { allRegion } from '@utils/constants';
import { useActions } from '@utils/hooks';
import type { LANG_LOCALE } from '@utils/types';

export const useGetReservationCountRanking = () => {
  const { locale } = useRouter() as { locale: LANG_LOCALE };
  const { getReservationCountRanking } = useActions({
    ...reservationCountRankingActions,
  });

  useEffect(() => {
    getReservationCountRanking(locale, allRegion);
  }, [locale]);
};
